import { model } from '@telekomconsalting/dex-guru-model'
import { ReactComponent as Bot } from 'app-images/icons/emoji/bot.svg'
import { ReactComponent as Elephant } from 'app-images/icons/emoji/elephant.svg'
import { ReactComponent as Handpen } from 'app-images/icons/emoji/hand-pen.svg'
import { ReactComponent as Icecream } from 'app-images/icons/emoji/icecream.svg'
import { ReactComponent as Icecream36 } from 'app-images/icons/emoji/icecream36.svg'
import { ReactComponent as Rooster } from 'app-images/icons/emoji/rooster.svg'
import { ReactComponent as Shark } from 'app-images/icons/emoji/shark.svg'
import { ReactComponent as SmartContract } from 'app-images/icons/emoji/smart_contract36.svg'
import { ReactComponent as Tiger } from 'app-images/icons/emoji/tiger.svg'
import { ReactComponent as Turtle } from 'app-images/icons/emoji/turtle.svg'
import { ReactComponent as Wallet } from 'app-images/icons/emoji/wallet.svg'
import { ReactComponent as Wallet36 } from 'app-images/icons/emoji/wallet36.svg'
import { ReactComponent as Whale } from 'app-images/icons/emoji/whale.svg'

import { CategoriesDataType } from './CategoryIcon'

export const CategoriesData: CategoriesDataType<model.CategoryWithTokenHolders> = {
  medium: {
    icon: Shark,
    tooltip: 'Active Trader with $100k-$500k of a trading volume last 30 days',
  },
  casual: {
    icon: Turtle,
    tooltip: 'Casual Trader with $10k-$100k of a trading volume last 30 days',
  },
  heavy: {
    icon: Whale,
    tooltip: 'Heavy Trader with $500k+ of a trading volume last 30 days',
  },
  bot: {
    icon: Bot,
    tooltip: 'Trader with 1000+ TXs in last 30 days. Most likely bot.',
  },
  tiger: {
    icon: Tiger,
    tooltip:
      'During the last 30 days, this Liquidity Provider continuously has had $100k-$500k locked inside different liquidity pools across all supported chains.',
  },
  elephant: {
    icon: Elephant,
    tooltip:
      'During the last 30 days, this Liquidity Provider continuously has had $500k+ locked inside different liquidity pools across all supported chains.',
  },
  rooster: {
    icon: Rooster,
    tooltip:
      'During the last 30 days, this Liquidity Provider continuously has had $10k-$100k locked inside different liquidity pools across all supported chains.',
  },
  smart_contract: {
    icon: Handpen,
    tooltip: 'Smart Contract',
  },
  liquidity_pool: {
    icon: Icecream,
    tooltip: 'Liquidity Pool',
  },
  wallet: {
    icon: Wallet,
    tooltip: 'Externally Owned Account',
  },
  mev_bot: {
    icon: Handpen,
    tooltip: '',
  },
}

export const CategoriesTitleData: CategoriesDataType<model.TokenHoldersCategoryName> = {
  smart_contract: {
    icon: SmartContract,
    tooltip: '',
    extraTooltip:
      'This address is a Smart Contract, a program/code that runs on the Ethereum blockchain. ',
  },
  liquidity_pool: {
    icon: Icecream36,
    tooltip: '',
    extraTooltip:
      'This address is a Liquidity Pool address. A liquidity pool is a collection of funds locked in a smart contract and is used to facilitate decentralized trading.',
  },
  wallet: {
    tooltip: '',
    extraTooltip:
      'This address is an EOA, aka Externally Owned Account. Externally owned – controlled by anyone with private keys.',
    icon: Wallet36,
  },
  mev_bot: {
    tooltip: '',
    icon: Handpen,
    extraTooltip: '',
  },
}
