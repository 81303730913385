import { model } from '@telekomconsalting/dex-guru-model'
import { FC, ReactNode, SVGProps } from 'react'

import { CategoriesData, CategoriesTitleData } from './CategoriesData'

export type CategoriesDataType<T extends string> = {
  [key in T]: {
    icon: FC<SVGProps<SVGSVGElement>>
    tooltip: ReactNode
    extraTooltip?: ReactNode
  }
}

interface CategoryIconProps<T extends string> {
  category?: T
  fallback?: ReactNode
  categories?: CategoriesDataType<T>
  tooltipType?: string
}

const CategoryIconGen = <T extends string>({
  category,
  fallback,
  categories,
  tooltipType,
}: CategoryIconProps<T>): ReactNode => {
  const categoryData = category && categories ? categories[category] : undefined
  if (categoryData) {
    const Icon = categoryData.icon
    return (
      <Icon
        className={category}
        data-tip={categoryData.tooltip}
        data-for="app-tooltip"
        data-type={tooltipType}
      />
    )
  } else {
    return <>{fallback || <div />}</>
  }
}

export const CategoryIcon: FC<CategoryIconProps<model.CategoryWithTokenHolders>> = (
  props: CategoryIconProps<model.CategoryWithTokenHolders>
) => {
  const Icon = CategoryIconGen<model.CategoryWithTokenHolders>({
    ...props,
    categories: CategoriesData,
  })
  return <>{Icon}</>
}

export const CategoryIconTitle: FC<CategoryIconProps<model.TokenHoldersCategoryName>> = (
  props: CategoryIconProps<model.TokenHoldersCategoryName>
) => {
  const Icon = CategoryIconGen<model.TokenHoldersCategoryName>({
    ...props,
    categories: CategoriesTitleData,
  })
  return <>{Icon}</>
}

export default CategoryIconGen
