import classNames from 'classnames'
import React, { FC, ReactNode } from 'react'

import { Heading } from './Heading'

export const Card: FC<{
  className?: string
  type?: 'light' | 'dark' | 'clear' | 'box'
  title?: ReactNode
  subtitle?: ReactNode
  disabled?: boolean
  tooltip?: string
  tooltipType?: 'dark' | 'light'
  tooltipClass?: string
  onClick?: () => void
}> = ({
  className,
  type = 'light',
  title,
  subtitle,
  disabled,
  tooltip,
  tooltipType,
  tooltipClass,
  onClick,
  children,
}) => {
  return (
    <div
      className={classNames('card', `card--${type}`, {
        [`${className}`]: !!className,
        'card--disabled': disabled,
      })}
      data-tip={tooltip}
      data-for={tooltip ? 'app-tooltip' : undefined}
      data-class={tooltip ? tooltipClass : undefined}
      data-type={tooltip ? tooltipType : undefined}
      onClick={!disabled ? onClick : undefined}>
      {(title || subtitle) && (
        <>
          <CardHeader title={title} subtitle={subtitle} />
          {children && <CardBody>{children}</CardBody>}
        </>
      )}
      {!(title && subtitle) && children && <>{children}</>}
    </div>
  )
}

export const CardBody: FC<{
  content?: string | React.ReactElement
}> = ({ content, children }) => {
  return <div className="card__body">{content || children}</div>
}

export const CardHeader: FC<{
  title?: React.ReactNode
  subtitle?: React.ReactNode
}> = ({ title, subtitle, children }) => {
  return (
    <div className="card__header">
      {title && (
        <Heading size="xs" className="card__title">
          {title}
        </Heading>
      )}
      {subtitle && <span className="card__subtitle">{subtitle}</span>}
      {children}
    </div>
  )
}

export const CardFooter: FC<{
  content?: string | React.ReactElement
}> = ({ content, children }) => {
  return <div className="card__footer">{content || children}</div>
}

export default Card
